import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './base-service';
import { HostedFeedbackConfig, HostedTrackingConfig } from '../ts-interfaces/hosted-tracking-config';

@Injectable({
  providedIn: 'root'
})
export class HostedTrackingConfigService extends BaseService {
  public hostedTrackingConfig: HostedTrackingConfig | undefined = undefined;
  public hostedFeedbackConfig: HostedFeedbackConfig | undefined = undefined;

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  public fetchHostedFeedbackConfig(brandExternalId: string | undefined = undefined, customDomain?: string) {
    // check cache
    if (this.hostedFeedbackConfig) {
      return of(this.hostedFeedbackConfig);
    }

    let httpparams: any = {
      a: true
    };

    let headers: any = {};

    if (brandExternalId) {
      httpparams['brandExternalId'] = brandExternalId;
      headers["brandExternalId"] = brandExternalId;
    }

    if (customDomain) {
      httpparams['customDomain'] = customDomain;
    }

    return this.httpClient.get(`${this.serverRootPathV2}hosted-feedback/config`, { params: httpparams })
      .pipe(
        tap((val: any) => this.hostedFeedbackConfig = val),
        catchError(e => { console.log('error in fetching config', e); return of(undefined) }));
  }


}
