import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute} from "@angular/router";
import { HostedTrackingConfig, AdConfig, Recommendations, FeedbackVisibility, CommunicationVisibility } from "../../ts-interfaces/hosted-tracking-config";
import { OrderInterface } from "../../ts-interfaces/order-interface";
import { DARK, DARK_CONSTRAST_COLOR, LIGHT, NOT_FOUND, TRACK_CONSTRAST_COLOR } from "../../shared/constants/generic-constants";
import { contrastColorOf } from "../../shared/functions/generate-contrast-color";
import { TerminalStatus } from 'src/app/shared/constants/terminal-status';
import { SystemStatus } from 'src/app/shared/constants/system-status';
import { OrderService } from "../../services/order.service";
import { environment } from 'src/environments/environment';
import { HostedTrackingConfigService } from 'src/app/services/hosted-tracking-config.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from 'src/app/services/translations.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ds-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy {
  public hostedTrackingConfig: HostedTrackingConfig | undefined;
  public secondaryAdsOne!: AdConfig | undefined;
  public secondaryAdsTwo!: AdConfig | undefined;
  public primaryAds: AdConfig | undefined;
  public order!: OrderInterface;
  public fetchedOrder: OrderInterface | undefined;
  isTerminalStatus: boolean = false;
  isSystemStatus: boolean = false;
  private streamSource: any;
  private validationToken: string | undefined;
  public isPageLoading: boolean = true;
  public recommendations!: Recommendations[] | undefined;
  public liveStreaming = false;
  subscribeToUpdateVisibility!: CommunicationVisibility;
  isSubscribeBoxVisible = false;
  feedbackVisibility!: FeedbackVisibility;
  isFeedbackBoxVisible: boolean = false;
  public translationsLoaded = false;

  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private hostedTrackingConfigService: HostedTrackingConfigService,
    private translate: TranslateService,
    private translationsService: TranslationsService
  ) { }

  ngOnInit() {
    this.addListener();
  }

  private addListener() {
    this.validationToken = this.activatedRoute.snapshot.queryParams.valToken;
    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage);
    } else {
      (<any>window).attachEvent("onmessage", this.receiveMessage);
    }
  }

  private receiveMessage = (event: MessageEvent) => {
    if (environment.appUrl.includes(event.origin)) {
      const urlSegments = this.activatedRoute.snapshot.url;
      const tenantId = urlSegments[1]?.path;
      const brandExternalId = urlSegments[2]?.path;
      const { hostedTrackingConfig, locale, token } = event.data;
      const formValue: HostedTrackingConfig = hostedTrackingConfig;
      this.translationsService.get(locale, token, tenantId, brandExternalId).subscribe({
        next: (translations) => {
          this.translate.setTranslation(locale, translations);
          this.translate.use(locale);
        },
        complete: () => {
          this.translationsLoaded = true;
        }
      });
      if (formValue) {
        this.hostedTrackingConfig = formValue;
        this.hostedTrackingConfigService.hostedTrackingConfig = { ...this.hostedTrackingConfig, googleMapsApiKey: this.activatedRoute.snapshot.queryParams.googleMapsApiKey }
        this.order = this.orderService.getFakeOrder();

        if (!this.order || !this.hostedTrackingConfig) {
          return;
        }

        this.fetchedOrder = this.order;
        this.order.brandExternalId = this.hostedTrackingConfig?.brandExternalId!;

        const { componentVisibility } = this.hostedTrackingConfig;
        this.subscribeToUpdateVisibility = componentVisibility?.subscription;
        this.feedbackVisibility = componentVisibility?.feedback;
        this.isFeedbackBoxVisible = this.feedbackVisibility.active;
        this.isSubscribeBoxVisible = this.subscribeToUpdateVisibility.active

        this.setupTheme();

        this.setUpAdsConfig();

        this.setPageTitle();

        this.setFontVariables();

        this.setupOrderVariables();

        this.setupRecommendations();

        this.isPageLoading = false;
        window.removeEventListener("message", this.receiveMessage);
      }
      if (!(event.source instanceof MessagePort) && !(event.source instanceof ServiceWorker)) {
        event?.source?.postMessage(this.validationToken, event.origin);
      }
    }
  }

  openModal(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'custom-class'
    });
  }

  private setupRecommendations() {
    if (!this.hostedTrackingConfig?.components?.recommendations) {
      return;
    } else if (this.hostedTrackingConfig.components.recommendations.url
    ) {
      const sampleRecommendationsJson = [
        {"imageUrl":"assets/images/preview-recommendation-1.jpg","productName":"Product 1","productUrl":""},
        {"imageUrl":"assets/images/preview-recommendation-2.jpg","productName":"Product 2","productUrl":""},
        {"imageUrl":"assets/images/preview-recommendation-3.jpg","productName":"Product 3","productUrl":""},
        {"imageUrl":"assets/images/preview-recommendation-4.jpg","productName":"Product 4","productUrl":""},
      ];
      this.recommendations = sampleRecommendationsJson;
    } else if (this.hostedTrackingConfig.components.recommendations.jsonFileId){
      this.recommendations = this.hostedTrackingConfig.components.recommendations.jsonFileId;
    }
  }

  private setupOrderVariables() {
    // terminal status is the status in which order is processed from DS side.
    this.isTerminalStatus = this.order && TerminalStatus.includes(this.order && this.order.status) || false;

    // system status is status which are internal to DS.
    // we don't show this to customers -  we show delivery info not available.
    this.isSystemStatus = this.order && SystemStatus.includes(this.order.status) || false;
  }

  private setUpAdsConfig() {
    // don't really need to put this check, but stupid typescript does not know that
    // I have handled the case when this variable is undefined. Sadly we cannot fix developers with tools.
    if (!this.hostedTrackingConfig) {
      return;
    }

    this.secondaryAdsOne = this.hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.type === "secondaryOne";
    });

    this.secondaryAdsTwo = this.hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.type === "secondaryTwo";
    });

    this.primaryAds = this.hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.type === "primary";
    });
  }

  private setupTheme() {
    // same reason I have put this check in setUpAdsConfig
    if (!this.hostedTrackingConfig) {
      return;
    }

    if (this.hostedTrackingConfig.branding?.color?.primary) {
      const contrastColor = contrastColorOf(this.hostedTrackingConfig.branding.color.primary);
      if (contrastColor === DARK_CONSTRAST_COLOR) {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, DARK);
      } else {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, LIGHT);
      }

      // primary color for site
      document.documentElement.style
        .setProperty('--primary', this.hostedTrackingConfig.branding.color.primary);
      // when you use primary as background use this as font color
      document.documentElement.style
        .setProperty('--primary-contrast', contrastColor); // contrasting black or white
    } else {
      // default primary color for site, when no primary color is given
      document.documentElement.style
        .setProperty('--primary', '#122344');
      // when you use primary as background use this as font color
      document.documentElement.style
        .setProperty('--primary-contrast', '#cccccc'); // contrasting black or white
    }

    // for site nav
    if (this.hostedTrackingConfig.components.header?.fontColor) {
      document.documentElement.style
        .setProperty('--nav-primary', this.hostedTrackingConfig.components.header.fontColor);
    }
    if (this.hostedTrackingConfig.components.header?.fontSize) {
      document.documentElement.style
        .setProperty('--nav-size', this.hostedTrackingConfig.components.header.fontSize + 'px');
    }
    if (this.hostedTrackingConfig.components.header?.backgroundColor) {
      document.documentElement.style
        .setProperty('--nav-bg', this.hostedTrackingConfig.components.header.backgroundColor);

      const navBgContrast = contrastColorOf(this.hostedTrackingConfig.components.header.backgroundColor);

      document.documentElement.style
        .setProperty('--nav-bg-contrast', navBgContrast);
    }
  }

  private setPageTitle() {
    // when we have order details show both order id and brand name
    // otherwise just show brand name
    if (this.order) {
      document.title = `${this.translate.instant('GENERIC.PLATFORM.ORDER_TEXT')} #${this.order.orderExternalId} | ${this.order.brandName}`;
    } else if (this.hostedTrackingConfig) {
      document.title = this.hostedTrackingConfig.brandName;
    } else {
      document.title = this.translate.instant('GENERIC.COMMON_PAGES.PAGE_NOT_FOUND_TEXT');
    }
  }

  private setFontVariables() {
    if (!this.hostedTrackingConfig) {
      return;
    }

    let content = "", heading = "", nav = "";

    if (this.hostedTrackingConfig.branding.fonts?.content) {
      const contentFontType = this.hostedTrackingConfig.branding.fonts.content.split('.').pop();

      content = `@font-face {
        font-family: 'content_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${this.hostedTrackingConfig.branding.fonts.content}) format('${contentFontType}');
      }`;
    }

    if (this.hostedTrackingConfig.branding.fonts?.heading) {
      const headingFontType = this.hostedTrackingConfig.branding.fonts.heading.split('.').pop();

      heading = `@font-face {
        font-family: 'heading_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${this.hostedTrackingConfig.branding.fonts.heading}) format('${headingFontType}');
      }`;
    }

    if (this.hostedTrackingConfig.branding.fonts?.navigation) {
      const navFontType = this.hostedTrackingConfig.branding.fonts.navigation.split('.').pop();

      nav = `@font-face {
        font-family: 'nav_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${this.hostedTrackingConfig.branding.fonts.navigation}) format('${navFontType}');
      }`;
    }

    const style = document.createElement('style');
    style.innerHTML = `
      ${content}
      ${heading}
      ${nav}
    `;

    document.head.appendChild(style);
  }

  public ngOnDestroy() {
    if (this.streamSource) {
      this.streamSource.close();
    }
  }
}
