import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from "@angular/router";
import { HostedTrackingConfig, AdConfig, Recommendations, CommunicationVisibility, FeedbackVisibility } from "../../ts-interfaces/hosted-tracking-config";
import { OrderInterface } from "../../ts-interfaces/order-interface";
import { DARK, DARK_CONSTRAST_COLOR, LIGHT, NOT_FOUND, TRACK_CONSTRAST_COLOR } from "../../shared/constants/generic-constants";
import { contrastColorOf } from "../../shared/functions/generate-contrast-color";
import { TerminalStatus } from 'src/app/shared/constants/terminal-status';
import { SystemStatus } from 'src/app/shared/constants/system-status';
import { TrackerService } from "../../services/tracker.service";
import { OrderService } from "../../services/order.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OrderTypeController, StatusGroupController } from '@deliverysolutions/order-status-mapping';
import { getDSPLogoURL } from 'src/app/shared/functions/get-dsp-logo-url';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ClevertapAnalyticsService } from 'src/app/services/clevertap-analytics.service';
import { NullEmptyChecker } from "@deliverysolutions/utils";
import { NEGATIVE_STATUSES } from 'src/app/shared/constants/negative-status';
import { Userpilot } from "userpilot";
import { MD5 } from "crypto-js";
import { TranslateService } from '@ngx-translate/core';
import { transformPayloadFormat } from 'src/app/shared/functions/transform-payload';
import { HostedTrackingConfigService } from 'src/app/services/hosted-tracking-config.service';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ds-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public hostedTrackingConfig: HostedTrackingConfig | undefined;
  public secondaryAdsOne!: AdConfig | undefined;
  public secondaryAdsTwo!: AdConfig | undefined;
  public recommendations: Recommendations[] = [];
  public primaryAds: AdConfig | undefined;
  public order: OrderInterface | undefined;
  isTerminalStatus: boolean = false;
  isSystemStatus: boolean = false;
  private streamSources: any[] = [];
  public fetchedOrder: OrderInterface | undefined;
  public isNavBackgroundWhite = false; // any default works

  private destroy$ = new Subject();
  public liveStreaming = false;
  public isClientToken = false;
  remainingPackages: OrderInterface[] = [];
  isSubscribeBoxVisible: boolean = false; // any default works
  isFeedbackBoxVisible: boolean = false; // any default works
  subscribeToUpdateVisibility!: CommunicationVisibility;
  feedbackVisibility!: FeedbackVisibility;
  childOrderList: OrderInterface[] = [];
  childOrderIdList: string[] = [];
  getDSPLogoURL = getDSPLogoURL;
  deliveryTimeOfChildOrders: Array<string | number | undefined> = [];
  statusGroup = new StatusGroupController();
  activeChildOrderIndex: number = 0
  public orderTypeController = OrderTypeController;
  showSiblingOrders!: boolean;
  isNegativeStatus!: boolean;
  systemStatusConstant = SystemStatus;

  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private trackerService: TrackerService,
    private orderService: OrderService,
    private gaAnalyticsService: GoogleAnalyticsService,
    private ctAnalyticsService: ClevertapAnalyticsService,
    private hostedTrackingConfigService: HostedTrackingConfigService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.gaAnalyticsService.setupDSGoogleAnalytics();
    this.activatedRoute.data.subscribe(async (data) => {
      data = transformPayloadFormat(data);
      if (
        data.hostedTrackingConfig === undefined || (data.hostedTrackingConfig && !data.hostedTrackingConfig.active) // this happens when there is no token in query params or brand id in url or in http error
      ) {
        this.router.navigate(['/service-unavailable'], { skipLocationChange: true });
        return;
      }

      this.hostedTrackingConfig = data.hostedTrackingConfig;

      /**
       * This is setting the transformed hostedTrackingConfig into hostedTrackingConfigService
       * Earlier this was happening with the help of hosted Tracking Config resolver but since we merged 
       * order and hostedTrackingConfig fetching into single api, we need to set the transformed hostedTrackingConfig variable in hostedTrackingConfigService
       */
      this.hostedTrackingConfigService.hostedTrackingConfig = data.hostedTrackingConfig;

      // data.orderDetails is undefiend when there is not order available
      this.fetchedOrder = data.orderDetails && data.orderDetails.data;
      if (this.fetchedOrder && this.fetchedOrder.redirectUrl) {
        window.open(this.fetchedOrder.redirectUrl, '_self');
      }

      // shipment order can have childOrders, in that case we set one of the child orders as the main order, which we show on the page
      if (this.fetchedOrder?.type === OrderTypeController.SHIPPING && Array.isArray(this.fetchedOrder?.htChildOrdersDto)) {
        this.order = this.fetchedOrder?.htChildOrdersDto[0];
        this.updateSelectedChildOrder(0);
      } else {
        this.order = this.fetchedOrder;
        this.updateOrderVariables();
      }

      if (this.fetchedOrder?.groupId && Array.isArray(this.fetchedOrder?.htChildOrdersDto)) {
        this.order = this.fetchedOrder?.htChildOrdersDto[0];
        this.showSiblingOrders = true;
        this.updateSelectedChildOrder(0);
      } else {
        this.order = this.fetchedOrder;
        this.showSiblingOrders = false;
        this.updateOrderVariables();
      }

      if (this.hostedTrackingConfig) {
        this.setupTheme(this.hostedTrackingConfig);
        this.setUpAdsConfig(this.hostedTrackingConfig);
        this.setFontVariables(this.hostedTrackingConfig);
        this.setupRecommendations(this.hostedTrackingConfig);
        this.setPageTitleAndFavicon();

        const { componentVisibility } = this.hostedTrackingConfig;
        this.subscribeToUpdateVisibility = componentVisibility.subscription;
        this.feedbackVisibility = componentVisibility.feedback;
        this.isFeedbackBoxVisible = this.feedbackVisibility.active;
        this.setSubscribeWidgetVisibility();
      }

      this.setupStreamingService();

      // this is to close overflow menu in case user clicks anywhere on the page
      if (this.fetchedOrder?.htChildOrdersDto?.length) {
        this.remainingPackages = this.fetchedOrder.htChildOrdersDto.slice(3);
        this.childOrderList = this.fetchedOrder.htChildOrdersDto || [];
        for (const childOrder of this.childOrderList) {
          if (childOrder.provider || childOrder.preferredProvider) {
            childOrder.providerName = (childOrder.carrier?.providerInfo.name === 'Self Delivery' ? childOrder.provider :
              childOrder.carrier?.providerInfo.name) || childOrder.provider || childOrder.preferredProvider;
          }
        }
        this.childOrderIdList = this.fetchedOrder.htChildOrdersDto.map((order: OrderInterface) => order._id);
        document.addEventListener('click', () => {
          document.querySelector('.packages-container .overflow-menu')?.classList.add('d-none');
        });
      }

      this.setupGoogleAnalytics();
      this.setupCleverTapAnalytics();
      this.setupUserPilotIdentifier();
    });

  }
  setupUserPilotIdentifier() {
    const tenantId = this.hostedTrackingConfig?.tenantId;
    const userId = MD5(this.fetchedOrder?.deliveryContact.phone!).toString();
    const brandName = this.hostedTrackingConfig?.brandName;
    Userpilot.identify(userId, {
      tenantId,
      company: {
        id: tenantId,
        module: `hosted-tracking`,
        brand: brandName,
      },
    });
  }
  setSubscribeWidgetVisibility() {
    this.isSubscribeBoxVisible = this.subscribeToUpdateVisibility.active && !this.isTerminalStatus;
  }

  public updateSelectedChildOrder(activeChildOrderIndex: number) {
    this.activeChildOrderIndex = activeChildOrderIndex;
    this.order = this.fetchedOrder?.htChildOrdersDto![this.activeChildOrderIndex];
    if (!this.hostedTrackingConfig?.componentVisibility?.proofOfDelivery && this.order && NullEmptyChecker.isNonEmptyArray(this.order.attachments)) {
      delete this.order.attachments;
    }
    this.updateOrderVariables();
  }

  private setupStreamingService() {
    let { token, trackingNumber, provider } = this.activatedRoute.snapshot.queryParams;
    let tenantId = this.hostedTrackingConfig?.tenantId;
    if (!token) {
      token = this.activatedRoute.snapshot.queryParams.ct;
      this.isClientToken = token ? true : false;
    }

    // sometimes order details aren't present,
    // in that case do nothing...
    // token is required for streaming service.
    if (!this.fetchedOrder || (!token && !(trackingNumber && provider))) {
      return;
    }

    if (Array.isArray(this.fetchedOrder?.htChildOrdersDto)) {

      this.fetchedOrder.htChildOrdersDto.forEach((childOrder, index) => {
        const childToken = childOrder.hostedTrackingUrl?.substring(childOrder?.hostedTrackingUrl?.lastIndexOf('=') + 1) || ''

        const streamSource = this.trackerService.subscribeTrackerInfo(childOrder._id, childToken, this.isClientToken, tenantId, trackingNumber, provider,
          (order) => {
            // on first call streaming returns empty object {}
            if (!order || !Object.keys(order)?.length) {
              return;
            }

            this.fetchedOrder!.htChildOrdersDto![index] = Object.assign({}, this.fetchedOrder!.htChildOrdersDto![index], order);

            this.fetchOrderStatus(this.fetchedOrder!.htChildOrdersDto![index]);

            if (index === this.activeChildOrderIndex) {
              this.updateSelectedChildOrder(index);
              this.setSubscribeWidgetVisibility();
            }
          },
          (error) => {
            console.log('streaming service error: ', error)
          }
        );

        this.streamSources.push(streamSource);
      });

    } else { // delivery, in-store-pickup, curbside
      const streamSource = this.trackerService.subscribeTrackerInfo(
        this.fetchedOrder._id,
        token,
        this.isClientToken,
        tenantId,
        trackingNumber,
        provider,
        (order) => {
          // on first call streaming returns empty object {}
          if (!order || !NullEmptyChecker.isNonEmptyArray(Object.keys(order))) {
            return;
          }

          this.order = Object.assign({}, this.order, order);
          if (!this.hostedTrackingConfig?.componentVisibility?.proofOfDelivery && this.order && NullEmptyChecker.isNonEmptyArray(this.order.attachments)) {
            delete this.order.attachments;
          }
          this.updateOrderVariables();
          this.setSubscribeWidgetVisibility();
          this.order && this.fetchOrderStatus(this.order);
        },
        (error) => { console.log('streaming service error: ', error) }
      );

      this.streamSources.push(streamSource);
    }
  }

  openModal(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'custom-class'
    });
  }

  private updateOrderVariables() {
    // terminal status is the status in which order is processed from DS side.
    this.isTerminalStatus = this.order && TerminalStatus.includes(this.order && this.order.status) || false;

    // system status is status which are internal to DS.
    // we don't show this to customers -  we show delivery info not available.
    this.isSystemStatus = this.order && SystemStatus.includes(this.order.status) || false;
    this.isNegativeStatus = this.order && NEGATIVE_STATUSES.includes(this.order.status) || false;
  }

  private fetchOrderStatus(order: OrderInterface) {
    this.orderService.getOrderStatus(order._id)
      .subscribe(data => {
        if (!data) {
          return;
        }

        if (Array.isArray(data.orderStatus)) {
          order.statusHistory = data.orderStatus;
        }

        if (Array.isArray(data.route)) {
          order.route = data.route;
        }
      });
  }

  private setupRecommendations(hostedTrackingConfig: HostedTrackingConfig) {
    const recommendations = hostedTrackingConfig.components.recommendations;
    const orderId = this.order ? this.order.orderExternalId : '';

    if (recommendations) {
      if (recommendations.url) {
        this.orderService.fetchExternalRecommendations(recommendations.url, orderId, 'hosted-tracking')
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            if (Array.isArray(result) && result.length) {
              this.recommendations = result;
            }

          });
      } else {
        this.recommendations = hostedTrackingConfig.components.recommendations.jsonFileId;
      }
    }
  }

  private setUpAdsConfig(hostedTrackingConfig: HostedTrackingConfig) {
    this.secondaryAdsOne = hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.type === "secondaryOne";
    });

    this.secondaryAdsTwo = hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.type === "secondaryTwo";
    });

    this.primaryAds = hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.type === "primary";
    });
  }

  // setup page theme. these css variables will be used cross page for proper brand experience
  private setupTheme(hostedTrackingConfig: HostedTrackingConfig) {
    if (hostedTrackingConfig.branding?.color?.primary) {
      const contrastColor = contrastColorOf(hostedTrackingConfig.branding.color.primary);
      if (contrastColor === DARK_CONSTRAST_COLOR) {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, DARK);
      } else {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, LIGHT);
      }

      // primary color for site
      document.documentElement.style
        .setProperty('--primary', hostedTrackingConfig.branding.color.primary);
      // when you use primary as background use this as font color
      document.documentElement.style
        .setProperty('--primary-contrast', contrastColor); // contrasting black or white
    } else {
      // default primary color for site, when no primary color is given
      document.documentElement.style
        .setProperty('--primary', '#122344');
      // when you use primary as background use this as font color
      document.documentElement.style
        .setProperty('--primary-contrast', '#cccccc'); // contrasting black or white
    }

    // for site nav
    if (hostedTrackingConfig.components.header?.fontColor) {
      document.documentElement.style
        .setProperty('--nav-primary', hostedTrackingConfig.components.header.fontColor);
    }
    if (hostedTrackingConfig.components.header?.fontSize) {
      document.documentElement.style
        .setProperty('--nav-size', hostedTrackingConfig.components.header.fontSize + 'px');
    }
    if (hostedTrackingConfig.components.header?.backgroundColor) {
      document.documentElement.style
        .setProperty('--nav-bg', hostedTrackingConfig.components.header.backgroundColor);

      const navBg = hostedTrackingConfig.components.header.backgroundColor;

      this.isNavBackgroundWhite = navBg === '#fff' || navBg === '#ffffff' || navBg === 'white';


      const navBgContrast = contrastColorOf(hostedTrackingConfig.components.header.backgroundColor);

      document.documentElement.style
        .setProperty('--nav-bg-contrast', navBgContrast);
    }
  }

  private setPageTitleAndFavicon() {
    if (
      this.hostedTrackingConfig
      && this.hostedTrackingConfig.branding.favicon?.active
      && this.hostedTrackingConfig.branding.favicon.pageTitle
    ) {
      document.title = this.hostedTrackingConfig.branding.favicon.pageTitle;
    } else if (this.order) {
      document.title = `${this.translate.instant('GENERIC.PLATFORM.ORDER_TEXT')} #${this.order.orderExternalId} | ${this.order.brandName}`;
    } else if (this.hostedTrackingConfig) {
      document.title = this.hostedTrackingConfig.brandName;
    } else {
      document.title = this.translate.instant('GENERIC.COMMON_PAGES.PAGE_NOT_FOUND_TEXT');
    }

    if (
      this.hostedTrackingConfig
      && this.hostedTrackingConfig.branding.favicon?.active
      && this.hostedTrackingConfig.branding.favicon.url
    ) {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.type = 'image/png';
      link.href = this.hostedTrackingConfig.branding.favicon.url;
      document.head.appendChild(link);
    }
  }

  // use fonts provider by brand for page
  private setFontVariables(hostedTrackingConfig: HostedTrackingConfig) {
    let content = "", heading = "", nav = "";

    if (hostedTrackingConfig.branding.fonts?.content) {
      const contentFontType = hostedTrackingConfig.branding.fonts.content.split('.').pop();

      content = `@font-face {
        font-family: 'content_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${hostedTrackingConfig.branding.fonts.content}) format('${contentFontType}');
      }`;
    }

    if (hostedTrackingConfig.branding.fonts?.heading) {
      const headingFontType = hostedTrackingConfig.branding.fonts.heading.split('.').pop();

      heading = `@font-face {
        font-family: 'heading_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${hostedTrackingConfig.branding.fonts.heading}) format('${headingFontType}');
      }`;
    }

    if (hostedTrackingConfig.branding.fonts?.navigation) {
      const navFontType = hostedTrackingConfig.branding.fonts.navigation.split('.').pop();

      nav = `@font-face {
        font-family: 'nav_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${hostedTrackingConfig.branding.fonts.navigation}) format('${navFontType}');
      }`;
    }

    const style = document.createElement('style');
    style.innerHTML = `
      ${content}
      ${heading}
      ${nav}
    `;

    document.head.appendChild(style);
  }

  selectShippingPackage(index: number) {
    this.updateSelectedChildOrder(index);
    this.setSubscribeWidgetVisibility();
  }

  toggleViewallSidebar() {
    const viewallSidebarCnt = document.getElementById("shipment-viewall-sidebar");
    if (!viewallSidebarCnt) {
      return;
    }

    const isVisible = viewallSidebarCnt.style.display !== 'none';

    // show sidebar
    if (!isVisible) {
      viewallSidebarCnt.style.removeProperty('display');
      viewallSidebarCnt.querySelector<HTMLElement>('.sidebar-backdrop')?.style.removeProperty('display');
      document.body.classList.add('overflow-hidden');

      // give sidebar slide-in effect
      setTimeout(() => {
        viewallSidebarCnt.querySelector('.view-all-sidebar')?.classList.remove('translateX100p');
      }, 0);
    } else { // hide sidebar
      // give sidebar slide-out effect
      viewallSidebarCnt.querySelector('.view-all-sidebar')?.classList.add('translateX100p');
      // we hide backdrop before we 'slide-out' actual sidebar
      viewallSidebarCnt.querySelector<HTMLElement>('.sidebar-backdrop')?.style.setProperty('display', 'none');
      document.body.classList.remove('overflow-hidden');

      // hide sidebar container after slide-out effect
      setTimeout(() => {
        viewallSidebarCnt.style.setProperty('display', 'none', 'important');
      }, 700);
    }
  }

  setupGoogleAnalytics() {
    this.hostedTrackingConfig && this.gaAnalyticsService.setupGoogleAnalytics(this.hostedTrackingConfig);
  }

  setupCleverTapAnalytics() {
    this.hostedTrackingConfig && this.ctAnalyticsService.setupClevertapAnalytics(this.hostedTrackingConfig);
  }

  ngOnDestroy() {
    this.streamSources.forEach(subs => subs.close());
  }

}
