import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderTypeController, StatusMasterController } from '@deliverysolutions/order-status-mapping';
import { zeroTrackingDefaultConfig } from 'src/app/shared/constants/generic-constants';
import { HostedTrackingConfig } from 'src/app/ts-interfaces/hosted-tracking-config';
import { OrderInterface } from 'src/app/ts-interfaces/order-interface';

@Component({
  selector: 'ds-store-driver-info',
  templateUrl: './store-driver-info.component.html',
  styleUrls: ['./store-driver-info.component.scss']
})
export class StoreDriverInfoComponent implements OnInit, OnChanges {
  @Input() order!: OrderInterface;
  @Input() hostedTrackingConfig!: HostedTrackingConfig;

  public isDriverActive = false;
  public isDriverPhoneVisible = false;
  public isDriverLocationVisible = false;
  public storeAddress = "";
  public isStoreLocationVisible = false;
  public isStorePhoneVisible = false;
  public isPickupOrder = false;
  public statusMasterData: any;
  public showPickupContactForTrackers = true;

  constructor() { }

  ngOnInit(): void {
    this.updateAddress();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.order?.currentValue || changes?.hostedTrackingConfig?.currentValue) {
      this.updateAddress();
    }
  }

  updateAddress() {
    this.isPickupOrder = this.order.type === OrderTypeController.IN_STORE_PICKUP || this.order.type === OrderTypeController.CURBSIDE;
    this.isDriverActive = this.hostedTrackingConfig?.componentVisibility?.driver?.active;
    this.isDriverPhoneVisible = this.hostedTrackingConfig?.componentVisibility?.driver?.communication?.isActiveCall;
    this.isDriverLocationVisible = this.hostedTrackingConfig?.componentVisibility?.driver?.communication?.isActiveLocation;
    this.isStoreLocationVisible = this.hostedTrackingConfig?.componentVisibility?.pickupLocation?.communication?.isActiveLocation;
    this.isStorePhoneVisible = this.hostedTrackingConfig?.componentVisibility?.pickupLocation?.communication?.isActiveCall;
    this.storeAddress = this.order?.pickUpAddress?.street;
    this.showPickupContactForTrackers = this.order?.pickUpContact?.phone !== zeroTrackingDefaultConfig.contact;
    if (this.order.pickUpAddress.street2) {
      this.storeAddress += ", ";
      this.storeAddress += this.order.pickUpAddress.street2;
    }

    this.storeAddress = `${this.storeAddress}, ${this.order.pickUpAddress.city}, ${this.order.pickUpAddress.state} ${this.order.pickUpAddress.zipcode}, ${this.order.pickUpAddress.country}`;
    const statusMaster = new StatusMasterController();
    this.statusMasterData = statusMaster.getStatusDataByStatus(this.order.status);
  }

}
