import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NullEmptyChecker } from "@deliverysolutions/utils";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers: any = {
      'x-compression': 'true',
      'h-token': 'na',
      'Cache-Control': 'no-store'
    };

    const urlPath = window.location.pathname.substr(1);
    const urlArray = urlPath && urlPath.split('/');
    const isFeedbackUrl = NullEmptyChecker.isNonEmptyArray(urlArray) && urlArray[0] === 'feedback';
    const isFeedbackPreviewUrl = NullEmptyChecker.isNonEmptyArray(urlArray) && urlArray[0] === 'feedback' && urlArray[1] === "preview";
    const isPreviewUrl = NullEmptyChecker.isNonEmptyArray(urlArray) && urlArray[0] === 'preview';
    const index = isFeedbackPreviewUrl ? 2 : (isFeedbackUrl ? 1 : (isPreviewUrl ? 1 : 0));
    const tenantId = urlArray[index];
    const brandExternalId = urlArray[index + 1];

    if (tenantId) {
      headers['tenantId'] = tenantId;
    }
    const params: any = new URLSearchParams(window.location.search);

    let token;
    if (params.get('token')) {
      token = params.get('token');
    }

    if (environment.env !== "local" && !window.location.host.includes('localhost') && !window.location.host.endsWith('deliverysolutions.co')) {
      // custom domain
      headers['custom-domain'] = window.location.host;
    }

    // pre authorized url has token
    if (token) {
      headers['h-token'] = token;
    }
    if (brandExternalId && tenantId) {
      // create token if token does not exists in query params
      const data = {
        tenantId, brandExternalId
      };

      headers['h-json'] = JSON.stringify(data);
    }

    if (params.get('provider') && params.get('trackingNumber')) {
      headers['provider'] = params.get('provider');
      headers['trackingnumber'] = params.get('trackingNumber');
    }

    return next.handle(request.clone({ setHeaders: headers }));
  }
}
